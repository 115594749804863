export const button = (theme: any) => ({
  styleOverrides: {
    root: {
      fontSize: '1.1875em',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      fontWeight: 600,
      height: '60px',
      minWidth: '190px',
      // textTransform: "none",
      borderRadius: '30px',
      WebkitTapHighlightColor: 'transparent',
    },
  },
})
